export const JackpotInformation = ({ jackpotAmount, jackpotWonTimes, userOptedIn, SPServiceActive, jackpotIdMatch }) => {
    const showSocialProofing = !SPServiceActive || userOptedIn || !jackpotIdMatch;
    return (
        <div className={`swj-information ${showSocialProofing ? 'simple' : ''}`}>
            <div className="swj-information-amount">${jackpotAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            {showSocialProofing &&
                jackpotWonTimes === 0 ?
                    <div className="swj-jackpot-growing">Jackpot is growing</div> :
                    <div className="swj-social-proofing">
                        <div className="sp-first-half"> Won {jackpotWonTimes} time{jackpotWonTimes === 1 ? '' : 's'} </div>
                        <div className="sp-second-half"> in the past day </div>
                    </div>
            }
        </div>
    );
}
