import { useSelector } from 'react-redux';
import { gameIdSelector, gameNameSelector, providerSelector } from '../../selectors/gameContext';
import { gtmPushToDatalayer } from '../helpers/user-tracking.helpers';
import { useCallback } from 'preact/hooks';

export const useGtmPush = () => {
    const gameId: string | undefined = useSelector(gameIdSelector);
    const gameName: string | undefined = useSelector(gameNameSelector);
    const gameProvider: string | undefined = useSelector(providerSelector);

    const gtmPush = useCallback(
        (payload: Record<string, unknown>) => {
            gtmPushToDatalayer(payload, { gameId, gameName, gameProvider });
        },
        [gameId, gameName, gameProvider]
    );

    return { gtmPush };
};
