import { locationReceived } from '../redux/geo-plc/actions/location-received';
import { licenseClear } from '../redux/geo-comply/license/actions/license-clear';
import { getCookieValue } from './helpers/cookies';
import { fetchEnd } from '../redux/fetch/actions/fetch-end';
import { locationRequestUid } from './helpers/plc-helper';
import { PLATFORM } from './constants/game-constants';
import { MESSAGES, COOKIES } from './constants';
import { getAppPlatform } from './helpers/app-platform';

export const isIosWrapper = () => {
    try {
        // // eslint-disable-next-line no-undef
        const isIOSCoookie = getCookieValue(COOKIES.WRAPPER) === 'iOS';
        return isIOSCoookie || window?.MobileWrapper?.getWrapperType?.() === PLATFORM.Ios;
    } catch (err) {
        return false;
    }
};

export const isAndroidWrapper = () => {
    return !!(
        (window.AndroidWrapper !== undefined && window.AndroidWrapper.isWrapper()) ||
        window?.MobileWrapper?.getWrapperType?.() === PLATFORM.Android
    );
};

export const isGameWindowDisabled = () => {
    return !!window?.MobileWrapper?.isGameWindowDisabled?.();
};

export const isRunningInsideWrapper = () => {
    try {
        return !!(window?.MobileWrapper?.isWrapper?.() || window?.AndroidWrapper?.isWrapper?.());
    } catch (err) {
        return false;
    }
};

export const isRunningInsideV3Wrapper = () => {
    try {
        return !!(window?.MobileWrapper?.isV3Enabled?.() || window?.AndroidWrapper?.isV3Enabled?.());
    } catch (err) {
        return false;
    }
};

export const requestWrapperGeoLocation = (license, userId, sessionId) => {
    window?.MobileWrapper?.getGeoPacket?.(license, userId, sessionId);
    window?.AndroidWrapper?.getGeoPacket?.(license, userId, sessionId);
};

export const registerWrapperGeolocationCallback = e => async dispatch => {
    const geopacketData = e.detail.encryptedGeolocation;
    console.info(`Received wrapper geopacket data: ${geopacketData}`);
    dispatch(locationReceived(geopacketData));
    dispatch(fetchEnd(locationRequestUid));
};

export const registerWrapperGeolocationRenew = () => async dispatch => {
    console.info(`Renew geolocation`);
    //clear license will trigger getting a new one and schedule a renew
    dispatch(licenseClear());
};

export const sendWrapperEvent = (eventType, data = null) => {
    window?.webkit?.messageHandlers?.iosEvent?.postMessage?.({ type: eventType, data });
    window?.MobileWrapper?.onEvent?.(eventType, data);
    window?.AndroidWrapper?.onEvent?.(eventType, data);
};

export const sendGAEvent = dataObject => {
    window?.MobileWrapper?.sendGAEvent?.(dataObject);
    window?.AndroidWrapper?.sendGAEvent?.(dataObject);
};

export const sendServerTime = serverTime => {
    window?.AndroidSTWrapper?.onServerTimeRetrieved?.(serverTime);
};

export const isWrapperRunningNewGeocomply = () =>
    (window && window?.MobileWrapper && window?.MobileWrapper?.triggerLocateUser) ||
    (window && window?.AndroidWrapper && window?.AndroidWrapper?.triggerLocateUser);

export const wrapperLocateUser = (clientAuthToken, userId, sessionId, userAuthToken) => {
    window?.AndroidWrapper?.triggerLocateUser?.(clientAuthToken, userId, sessionId, userAuthToken);
    window?.MobileWrapper?.triggerLocateUser?.(clientAuthToken, userId, sessionId, userAuthToken);
};

export const sendIosSessionExpiredMessage = () => {
    if (isIosWrapper()) {
        window?.postMessage({ type: MESSAGES.SESSION_EXPIRED }, '*');
    }
};

export const sendIosTokenRefreshMessage = payload => {
    if (isIosWrapper()) {
        window?.postMessage({ type: MESSAGES.TOKEN_REFRESH, data: payload }, '*');
    }
};

export const openExternalWebView = (e, url) => {
    e.preventDefault();
    (getAppPlatform() === PLATFORM.Xsell) ?
        sendWrapperEvent(MESSAGES.OPEN_EXTERNAL_WEBVIEW, url) :
        window?.Android?.redirectToBrowser(url);
}
